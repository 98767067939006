import React from "react"
// Images
// import DayIcon from "images/daymode.svg"
// import NightIcon from "images/nightmode.svg"
import { Link } from "gatsby"
// Styles
// import { ImgButton } from "./ModeButton.styles"
import { SloganText } from "./ModeButton.styles"

const ModeButton = ({ darkMode, setDarkMode }) => (
  <SloganText>
    Free Travel itineraries
    <br />Activity plans
    <br /><Link to="/page/join_the_community">Join our community!</Link>
  </SloganText>
  // <ImgButton
  //   src={darkMode ? NightIcon : DayIcon}
  //   alt="mode"
  //   onClick={() => setDarkMode(prev => !prev)}
  // />
)

export default ModeButton