import React, { useContext } from "react"
// Hooks
import { useSiteConfigQuery } from "hooks/useSiteConfigQuery"

import { CurrentUserContext } from '../../context/CurrentUserProvider'
import navigateWithAlert from '../../functions/navigateWithAlert'

// create an array of log out items
const GetMenuItems = () => {
    const siteConfig = useSiteConfigQuery()

    const { isLoggedIn, logout } = useContext(CurrentUserContext)

    const onCompletion = () => {
        console.log('[logout > onCompletion] #fdvsd')
        
        navigateWithAlert('/', 'Successfully logged out')
    }

    const items = siteConfig.menu

    console.log('==== isLoggedIn: ', isLoggedIn)

    if (isLoggedIn()) {
        items[2] = {
            id: "menu-item-3",
            link: "/MyBucktlist",
            name: "My Bktlist"
        }
        items[3] = {
            id: "menu-item-4",
            onClick: logout,
            onCompletion: onCompletion,
            name: "Logout"
        }
    } else {
        items[2] = {
            id: "menu-item-3",
            link: "/login",
            name: "Login"
        }
        
        // elements must be removed manually
        items.splice(3, 1)
    }

    return items
}

export default GetMenuItems