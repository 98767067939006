import styled from "styled-components"

export const Nav = styled.ul`
  margin-left: 15px;
  display: none;
  list-style-type: none;
  padding: 0;
  font-family: "Muli Regular", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.textDark};
  }

  li {
    display: inline-block;
    margin-right: 20px;
    transition: opacity 0.5s ease;
    cursor: pointer;
    /* border: 10px solid #333; */
    a {
      color: #ccc;
    }
    
    

    .active {
      font-weight: 800;
      color: #aaa;
    }

    :last-child {
      margin-right: 0;
    }

    :hover {
      /* opacity: 0.7; */

      /* color: #0f0 !important; */
      color: #ddd;
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 768px) {
    display: block;
  }
`
