import React from "react"
import { useState, useEffect, useRef } from 'react';
import { messageService } from "../../services/message.service"
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types';

// allows different parts of the app to create a user alert that can fade (after 3 seconds)
// uses observable
// usage:
// - import { messageService } from '../../services/message.service'
// - messageService.sendMessageAutoClose('Error: unable to remove this saved item.', 'error');
function AlertToaster ({fade}) {
    const theme = useTheme();
    const [alerts, setAlerts] = useState(() => {return []});

    console.log('[AlertToaster] fade parameter: #rwfewe', fade, ' alerts: ', alerts)

    console.log('alerts: #43sf34 ', alerts)
    
    useEffect(() => {
        // subscribe to alerts generated in other parts of the app
        const subscription = messageService.getMessage().subscribe(alert => {
            console.log('[AlertToaster] new alert: : ' , alert)
            console.log('[AlertToaster] pre existing alerts: #43sf34: ', alerts)

            if (alert) {
                // add alert to local state if not empty
                alert.itemId = Math.random();
                setAlerts(alerts => ([...alerts, alert]));

                if (alert.autoClose) {
                    console.log('auto close turned on #45zzfrt')
                    setTimeout(() => removeAlert(alert), 3000);
                }
            } else {
                // clear alerts when empty alert received
                setAlerts([]);
            }
            console.log ('[AlertToaster] Final alerts #43sf34: ', alerts)
        });

        // clean up function that runs when the component unmounts
        return () => {
            // unsubscribe to avoid memory leaks
            subscription.unsubscribe();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeAlert = (alert) => {
        const removeAlert = (alerts => alerts.filter(x => x.itemId !== alert.itemId))
        console.log('[AlertToaster] fade #57hg: ', fade)

        if (fade) {
            // fade out alert
            setAlerts(alerts => alerts.map(x => x.itemId === alert.itemId ? { ...x, fade: true } : x));

            // remove alert after faded out
            setTimeout(() => {
                console.log('[AlertToaster] setting time out for removal of toaster alert #6yrt9g')
                setAlerts(alerts => alerts.filter(x => x.itemId !== alert.itemId));
            }, 250);
        } else {
            // remove alert
            setAlerts(alerts => alerts.filter(x => x.itemId !== alert.itemId));
        }
    }

    const alertType = {
        success: 'success',
        error: 'error',
        info: 'info',
        warning: 'warning'
    }

    // create the classes for the alert item
    const cssClasses = (alert) => {
        console.log('[alert -> cssClasses] alert: ', alert)
        if (!alert) return;

        const classes = ['alert', 'alert-dismissable'];

        console.log('[AlertToaster] alertType: ', alertType)
                
        const alertTypeClass = {
            [alertType.success]: 'alert alert-success',
            [alertType.error]: 'alert alert-danger',
            [alertType.info]: 'alert alert-info',
            [alertType.warning]: 'alert alert-warning'
        }

        // const curAlertType = 'success'
        var type = alert.type
        if (!alertTypeClass[type]) {
            throw new Error ('alert with the type: "' + alert.type + '" is not recognised. Alert message is: "' + alert.text + '"' )
        }
        classes.push(alertTypeClass[type]);

        if (alert.fade) {
            classes.push('fade');
        }

        return classes.join(' ');
    }
    
    return (
        <div className="jumbotron" style={{padding: "0px 0px", margin: "0px", backgroundColor: theme.colors.background}}>
            <div className="container text-center">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">
                        {alerts.map((message) => (
                            <div key={message.itemId} className={cssClasses(message)} style={{margin: "1rem 0rem"}}>
                                {console.log('message #459fs23: ', message)}
                                <a className="close" onClick={() => removeAlert(message)}>&times;</a>
                                {message.text}
                            </div>
                        ))}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

AlertToaster.propTypes = {
    // id: PropTypes.string,
    fade: PropTypes.bool
};

AlertToaster.defaultProps = {
    // id: 'default-alert',
    fade: true
};


export default AlertToaster