import React, { useContext } from "react"
import { Link } from "gatsby"
// Styles
import { OverrideGlobalStyle, Wrapper, Nav } from "./MobileMenu.styles"

const MobileMenu = ({ menuOpen, items }) => {

  // todo: redirect on success to mybktlist + present toaster alert of successful login
  // take person to previous page before attempting login? (as they probably went there to do something but weren't logged in)

  return (
    <>
      <OverrideGlobalStyle menuOpen={menuOpen} />
      <Wrapper menuOpen={menuOpen}>
        <Nav>
          {items.map(item => (
            <li key={item.id}>
              {item.link && 
                <Link to={item.link} activeClassName="active">
                  {item.name}
                </Link>
              }
              {item.onClick &&
                <a href="#" onClick={item.onClick}>
                  test
                </a>
              }
            </li>
          ))}
        </Nav>
      </Wrapper>
    </>
  )
}

export default MobileMenu