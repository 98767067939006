import { navigate } from 'gatsby'

// when using this, make sure that the page has the useEffect to notice the (alert msg) state being passed in
const navigateWithAlert = (path, alertMsg, type) => {
    console.log('[navigateWithAlert] >going to path: ' + path)
    navigate(path, { state: {
        alert: {
            msg: alertMsg,
            type: type
        }
    }})

    return true
}

export default navigateWithAlert

// custom alerts:

export const mustBeLoggedIn = () => {
    navigateWithAlert('/login', 'Error: You must be logged in to do this. Please login or crate a new account.', 'error')
}