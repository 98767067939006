import React, { useState, useContext } from "react"
import { Link } from "gatsby"
// Components
import Menu from "components/Menu"
import Hamburger from "components/Hamburger"
import MobileMenu from "components/MobileMenu"
import ModeButton from "components/ModeButton"
// Context
import { ModeContext } from "context/ModeProvider"
// Styles
import { Wrapper } from "./Header.styles"
import GetMenuItems from '../../functions/GetMenuItems'

const Header = ({ siteTitle = `Bucktlist.com` }) => {
  const [darkMode, setDarkMode] = useContext(ModeContext)
  const [menuOpen, setMenuOpen] = useState(false)

  // console.log ('siteConfig.menu (#34vr0m) = ', siteConfig.menu)
  // const { processUserResponseData } = useContext(CurrentUserContext)

  const items = GetMenuItems()

  console.log('[Menu] items: ', items)

  return (
    <Wrapper>
      <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <MobileMenu menuOpen={menuOpen} items={items} />
      <Menu items={items} />
      <Link to="/">
        {/* <Logo src={siteConfig.logo.publicURL} alt={siteTitle} /> */}
        <h1>{ siteTitle }</h1>
      </Link>
      <ModeButton darkMode={darkMode} setDarkMode={setDarkMode} />
    </Wrapper>
  )
}

export default Header