import styled from "styled-components"

export const ImgButton = styled.img`
  margin-right: 15px;
  width: 35px;
  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    transform: scale(1.1);
  }
`

export const SloganText = styled.div`
  margin-right: 15px;
  /* color: #; */
  font-size: .7rem;
  font-style: italic;
  /* display: none; */

  @media screen and (min-width: 768px) {
    font-size: .9rem;
    /* display: block;
    content:""; */
  }

`