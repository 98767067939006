import React from "react"
import { Link } from "gatsby"
// Styles
import { Nav } from "./Menu.styles"

const Menu = ({ items }) => (
  <Nav>
    {items.map(item => (
      <li key={item.id}>
        {item.link && 
          <Link to={item.link} activeClassName="active">
            {item.name}
          </Link>
        }
        {item.onClick &&
          <a onClick={() => {item.onClick(item.onCompletion)}}>
            {item.name}
          </a>
        }
      </li>
    ))}
  </Nav>
)

export default Menu