import { createGlobalStyle } from "styled-components"
import MuliRegular from "fonts/Muli-Regular.woff"
import MuliBlack from "fonts/Muli-Black.woff"

import styled from "styled-components"

export const colors = {
  // key / "legend": (note, cannot be called "druapl content types" as "highlight" is not a drupal content type, it's a field type.)
  legendItinerary:  '#3092f7',
  legendLocation:   'rgb(249, 0, 97)',
  legendHighlight:  '#a7a7a7',
  legendActivity:   '#f9ed61',
  legendPhotograph: '#61f966',
  // use opacity .65 for "selected"
  // legendHighlightSelected:  '#646464',

  // old / original colors:
  white:        "#fff",
  darkGrey:     "#1a1c20",
  deepDarkGrey: "#404040",
  blueShade1:   "#215973",
  blueShade2:   "#eee",   // #6fadc6
  blueShade3:   "#d1e1e9",
}

export const darkTheme = {
  colors: {
    
    background: colors.darkGrey,
    menuBackground: colors.blueShade1,
    textDark: colors.blueShade3,
    headers: colors.blueShade2,
    textSecondary: colors.blueShade3,
  },
}

export const lightTheme = {
  colors: {
    background: colors.white,
    menuBackground: colors.blueShade3,
    textDark: colors.blueShade1,
    headers: colors.blueShade2,
    textSecondary: colors.deepDarkGrey,
  },
}

// "types" / legend <mark> - ussually used for highlights page
export const ItineraryTypeMark = styled.mark`
  margin-top:         6px;
  padding:            0px 2px;
  background-color:   ${colors.legendItinerary};
`

export const HighlightTypeMark = styled.mark`
// border: 1px solid #0f0;

  margin-top:         6px;
  padding:            0px 2px;
  background-color:   ${colors.legendHighlight};
  
`

export const LocationTypeMark = styled.mark`
  margin-top:         6px;
  padding:            0px 2px;
  background-color:   ${colors.legendLocation};
  
`

export const PhotographTypeMark = styled.mark`
  margin-top:         6px;
  padding:            0px 2px;
  background-color:   ${colors.legendPhotograph};
  
`

export const ActivityTypeMark = styled.mark`
  margin-top:         6px;
  padding:            0px 2px;
  background-color:   ${colors.legendActivity};
`

export const GlobalStyles = createGlobalStyle`
  :root {
    --site-max-width: 1200px;
  }

  @font-face {
    font-family: 'Muli Regular';
    src: local('Muli Regular'), local('MuliRegular'),
      url(${MuliRegular}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Muli Black';
    src: local('Muli Black'), local('MuliBlack'),
      url(${MuliBlack}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.textSecondary};
    font-family: "Muli Regular", Arial, Helvetica, sans-serif;
    margin: 0;
    /* padding: 0 20px 80px 20px; */
    transition: all 0.5s ease;
  }

  main {
    max-width: 960px;
    margin: 00px auto;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.colors.headers};
    font-family: "Muli Black", Arial, Helvetica, sans-serif;
    transition: all 0.5s ease;
    letter-spacing: 1px;
  }

  a:hover {
    color: #eee;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.2;

    @media screen and (min-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }

  h1 {
    font-size: 1.4rem;
    @media screen and (min-width: 768px) {
      font-size: 2.4rem
    }
  }

  h2 {
    margin-top: .2rem;
    margin-bottom: .1rem;
  }

  h2 {
    font-size: 1.3rem;
    letter-spacing: 1.8px;
    /* text-transform: uppercase; */
    color: #eee;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 1rem;
    letter-spacing: 1.5px;
    margin: 30px 0 -15px 0;

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  h1 a, h2 a, h3 a {
    color: #eee;
    text-decoration: none;
  }
`

export const Description = styled.div`
  /* border: 1px solid #0f0; */

  h3 {
    margin-top: 10px;
  }

  h3.highlights {
    /* border: 10px solid #0f0; */
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 5px;
    padding: 0px 5px;
    font-weight: normal;
    color :#000;
    display: inline-block;
    background-color: ${colors.legendHighlight};
  }

  p {
    margin-top: 0px;
      /* border: 1px solid #0f0; */
  }
`

export const NormalPadding = styled.div`
  padding: 0px 15px;
`